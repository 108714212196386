<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->

      <!-- /Brand logo-->

      <!-- Left Text-->
      <!-- <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            width="720"
            height="720"
            fluid
            src="images/login/test2.gif"
            alt="Login V2"
          />
        </div>
      </b-col> -->
      <!-- /Left Text-->

      <!-- Login-->
      <b-col sm="12" md="12" lg="12" class="login-bg"></b-col>

      <b-col
        v-if="!is_login_success"
        lg="4"
        offset-lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col sm="8" md="6" lg="12">
          <b-card-text class="mb-1 m font-weight-bold" title-tag="h2">
            <center>
              <b-img
                class="mb-3"
                :style="{
                  filter: skin == 'dark' ? 'brightness(0) invert(1)' : '',
                }"
                width="240px"
                height="90px"
                src="v_main_logo.png"
                alt="logo"
              />
            </center>
          </b-card-text>
          <b-card-title
            class="mb-1 font-weight-bold center-text"
            title-tag="h2"
          >
            {{ lang("t_welcome") }}
          </b-card-title>
          <b-card-text class="mb-2 center-text">
            {{ lang("t_pleaseSingIn") }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{}">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- username -->
              <b-form-group class="mb-2">
                <label for="login-username">{{ lang("t_username") }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="login-username"
                    :placeholder="lang('t_userName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ lang("t_password") }}</label>
                  <b-link @click="forgot_password">
                    <small>{{ lang("t_forgotPassword") }}</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="lang('t_password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group class="mb-3">
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ lang("t_rememberMe") }}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block>
                {{ lang("t_signIn") }}
              </b-button>
            </b-form>
          </validation-observer>
          <!-- <div>
            <center>
              <b-img
                style="width: 50%; height: 50%"
                class="mt-5"
                src="tt_logo.png"
                alt="logo"
              />
            </center>
          </div> -->

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <b-col
        v-else
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
        offset-lg="4"
      >
        <b-col sm="8" md="6" lg="12">
          <b-card-text class="mb-1 font-weight-bold" title-tag="h2">
            <center>
              <b-img
                class="mb-3"
                :style="{
                  filter: skin == 'dark' ? 'brightness(0) invert(1)' : '',
                }"
                width="240px"
                height="90px"
                src="v_main_logo.png"
                alt="logo"
              />
            </center>
          </b-card-text>
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            {{ `${lang("t_hello")} ${username.split(".")[0]},` }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ lang("t_PleaseSelectProjetAndQueues") }}
          </b-card-text>

          <!-- username -->
          <b-form-group class="mb-2">
            <v-select
              @input="selected_queues = []"
              v-model="selected_project"
              :options="projects"
              :placeholder="lang('t_projects')"
              label="display_name"
              :reduce="(val) => val.internal_name"
            >
            </v-select>
          </b-form-group>

          <!-- forgot password -->
          <b-form-group class="mb-3">
            <v-select
              v-model="selected_queues"
              :options="queues.filter((e) => e.project == selected_project)"
              :placeholder="lang('t_queues')"
              label="display_name"
              :reduce="(val) => val.internal_name"
              multiple
              @option:deselected="deselect_queue"
              :selectable="() => static_queues.length == 0"
            >
              <template #search="{ events, attributes }">
                <input
                  class="vs__search"
                  v-on="events"
                  v-bind="attributes"
                  maxlength="2"
                />
              </template>
              <template #list-header>
                <div class="w-100 mb-50">
                  <b-button
                    :disabled="static_queues.length > 0"
                    class="w-50"
                    size="sm"
                    variant="outline-primary"
                    @click="
                      () => {
                        selected_queues = queues
                          .filter((e) => e.project == selected_project)
                          .map((e) => e.internal_name);
                      }
                    "
                  >
                    <feather-icon size="14" icon="CheckCircleIcon" />
                    {{ lang("t_selectAll") }}
                  </b-button>
                  <b-button
                    :disabled="static_queues.length > 0"
                    class="w-50"
                    size="sm"
                    variant="outline-danger"
                    @click="
                      () => {
                        selected_queues = [];
                      }
                    "
                  >
                    <feather-icon size="14" icon="XOctagonIcon" />
                    {{ lang("t_cleanSelections") }}
                  </b-button>
                </div>
              </template>
            </v-select>
          </b-form-group>

          <!-- checkbox -->
          <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ lang("t_rememberMe") }}
                </b-form-checkbox>
              </b-form-group> -->

          <!-- submit buttons -->
          <b-button
            @click="start"
            v-if="selected_queues.length > 0"
            variant="primary"
            block
          >
            {{ lang("t_start") }}
          </b-button>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>

      <!-- <div>
        <center>
          <b-img
            style="width: 50%; height: 50%"
            class="mt-5"
            src="tt_logo.png"
            alt="logo"
          />
        </center>
      </div> -->
      <!-- <b-col v-else lg="4" class="d-flex auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Proje & Kuyruk Seçimi
          </b-card-title>
          <b-card-text class="mb-2"> Lütfen seçim yapınız. </b-card-text>

          <b-card no-body sub-title="Projeler">
            <b-card-title>Projeler</b-card-title>
            <b-row>
              <b-col>
                <b-list-group style="max-height: 20vh">
                  <b-list-group-item
                    v-for="(item, i) in projects"
                    :key="i"
                    :active="selected_project == item.internal_name"
                    class="flex-column align-items-start"
                    @click="
                      selected_project = item.internal_name;
                      selected_queues = [];
                    "
                  >
                    <b-card-text class="mb-1">
                      {{ item.display_name }}
                    </b-card-text>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
          </b-card>
          <b-card no-body sub-title="Kuyruklar" v-if="selected_project != ''">
            <b-card-title>Kuyruklar</b-card-title>

            <b-row>
              <b-col>
                <b-list-group style="max-height: 20vh">
                  <b-list-group-item
                    v-for="(item, i) in queues.filter(
                      (e) => e.project == selected_project
                    )"
                    :key="i"
                    :active="selected_queues.includes(item.internal_name)"
                    class="flex-column align-items-start"
                    @click="select_queue(item)"
                  >
                    <b-card-text class="mb-1">
                      {{ item.display_name }}
                    </b-card-text>
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
          </b-card>
          <hr v-if="selected_queues.length > 0" />
          <b-button
            @click="start"
            v-if="selected_queues.length > 0"
            variant="success"
            block
          >
            {{ "Başlat" }}
          </b-button>
        </b-col>
      </b-col> -->
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BListGroup, BListGroupItem, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useAppConfig from '@core/app-config/useAppConfig'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    vSelect,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      status: true,
      password: '',
      username: '',
      sideImg: '@/assets/images/logo/v_main_logo.png',
      is_login_success: false,
      projects: [],
      queues: [],
      selected_project: '',
      selected_queues: [],

      // validation rules
      required,
      email,
    }
  },
  setup() {
    // App Name
    const { skin } = useAppConfig()

    return {
      skin
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
      return this.sideImg
      // }
      return this.sideImg
    },
  },
  methods: {
    async getRegion() {
      if (!window.localStorage.getItem('language')) {
        var response = (
          await this.$http_in.get(`Auth/v1/Region`)
        ).data;
        if (response == "TR") {
          globalThis.selected_lang = 'tr';
          globalThis.selectLang = selectLang;
          globalThis._lang = globalThis._l(window.localStorage.getItem('language') ?? "tr");
          window.localStorage.setItem('language', "tr");
          window.location.reload();
        } else {
          globalThis.selected_lang = 'en';
          globalThis.selectLang = selectLang;
          globalThis._lang = _l(window.localStorage.getItem('language') ?? "en");
          window.localStorage.setItem('language', "en");
          window.location.reload();
        }
        return;
      }
      globalThis.selected_lang = 'en';
      globalThis.selectLang = selectLang;
      globalThis._lang = _l(window.localStorage.getItem('language') ?? "en");
    },
    async forgot_password() {
      if (this.username.trim().length > 0) {

        let salty_username = btoa(this.username.trim()).split('').reverse().join('');
        var response = (
          await this.$http_in.get(`sch/v1/ForgotPassword/${salty_username}`,)
        ).data;

        this.$bvToast.toast(`${globalThis._lang(response.message)}`, {
          title: `${globalThis._lang("t_notification")}`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: response.acknowledge ? 'success' : 'danger',
        })
      }

    },
    deselect_queue(item) {
      if (this.static_queues.length > 0) {
        this.selected_queues = this.static_queues;

      }
    },
    async start() {
      var response = (
        await this.$http_in.post(`Auth/v1/AddProjectAndQueuePermissions`, {
          project_name: this.selected_project,
          queues: this.selected_queues,
        })
      ).data;
      // console.log("privileges", response.permissions);
      // GET PRIVILEGES
      globalThis.register_info = response.register_info;
      globalThis.user = response.user;
      console.log("response.user", response.user);

      var _response = (
        await this.$http_in.get(`agent/v1/UserInfoRefresh`)
      ).data;

      globalThis.user = {
        ...globalThis.user,
        ..._response
      };
      this.$http_in.defaults.headers.common['text-channel-authorization'] = globalThis.user.text_channel_token;
      this.$http_in.defaults.headers.common['text-channel-user'] = globalThis.user.text_channel_user_id;

      console.log(_response);
      globalThis.permissions = {};
      for (const item of response.permissions) {
        if (globalThis.permissions[item.split(':')[0]] == undefined) {
          globalThis.permissions[item.split(':')[0]] = [item.split(':')[1]];
        }
        else {
          globalThis.permissions[item.split(':')[0]].push(item.split(':')[1])
        }
      }

      if (this.status) {
        let _userQueues = JSON.parse(localStorage.getItem('userQueues'));
        if (![undefined, null].includes(_userQueues)) {
          let _data = _userQueues.find(e => e.username == this.username);
          if (![undefined, null].includes(_data)) {
            _data.project = this.selected_project;
            _data.queues = this.selected_queues;
          }
          else {
            _userQueues.push({
              username: this.username,
              project: this.selected_project,
              queues: this.selected_queues,
            })
          }
        } else {
          _userQueues = [];
          _userQueues.push({
            username: this.username,
            project: this.selected_project,
            queues: this.selected_queues,
          })
        }

        globalThis.projects = this.projects;
        localStorage.setItem('userQueues', JSON.stringify(_userQueues))
      }

      // this.register(response.register_info);

      this.$router.replace('/agent_dashboard');

    },

    select_queue(queue) {
      if (this.selected_queues.includes(queue.internal_name)) {
        this.selected_queues = this.selected_queues.filter(e => e != queue.internal_name)
      }
      else {
        this.selected_queues.push(queue.internal_name);
      }
    },

    async get_user_project_and_queues() {
      var response = (
        await this.$http_in.get(`agent/v1/UserProject`)
      ).data;

      this.projects = response.projects;
      this.queues = response.queues;

      this.static_queues = [];
      for (const item of response.queues) {
        if (item.static_users.includes(this.username)) {
          localStorage.removeItem('userQueues');
          this.static_queues.push(item.internal_name);
          this.selected_project = item.project;
          globalThis.static_user = true;
        }
      }
      // console.log("this.static_queues", this.static_queues);
      if (this.static_queues.length > 0) {
        this.selected_queues = this.static_queues;

      }

    },
    async login() {
      var success = true; //await this.$refs.loginForm.validate();
      if (success) {
        var response = (
          await this.$http_in.post(`Auth/v1/Login`, {
            username: this.username,
            password: this.password
          })
        ).data;

        // console.log("resp", response);
        if (response.token == "Username or password is invalid.") {
          this.$bvToast.toast(`${globalThis._lang("t_usernameOrPasswordIsInvalid")}`, {
            title: `${globalThis._lang("t_warning")}`,
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
            variant: 'warning',
          });
          return;
        }
        if (response.token == "Tenant state is passive") {
          this.$bvToast.toast(`Tenant state is passive`, {
            title: `${globalThis._lang("t_warning")}`,
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
            variant: 'warning',
          })
          return;
        }
        console.log("AAA", response);
        globalThis.username = this.username;
        globalThis.qm_token = response.token;
        this.$http_in.defaults.headers.common['authorization'] = response.token;
        this.$http_in.defaults.headers.common['username'] = this.username;


        this.is_login_success = true;
        const userData = { password: this.password, username: this.username }

        if (this.status) {
          localStorage.setItem('userData', JSON.stringify(userData))
        }

        let userQueues = JSON.parse(localStorage.getItem('userQueues'));
        if (![undefined, null].includes(userQueues)) {
          let _data = userQueues.find(e => e.username == this.username);
          if (![undefined, null].includes(_data)) {
            this.selected_project = _data.project;
            this.selected_queues = _data.queues;
          }
        }


        // this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
        // .then(() => {

        this.$bvToast.toast(`${globalThis._lang("t_successfullyLoggedIn")}`, {
          title: `${globalThis._lang("t_welcome")} ${userData.username}`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
          variant: 'success',
        })

        // this.$toast({
        //   component: ToastificationContent,
        //   toaster: 'b-toaster-bottom-right',
        //   props: {
        //     title: `Hoşgeldin ${userData.username}`,
        //     icon: 'CoffeeIcon',
        //     variant: 'success',
        //     text: `Başarılı bir şekilde giriş yapıldı.`,
        //   },
        // });

        await this.get_user_project_and_queues();



        // })
        // })
        // .catch(error => {
        // this.$refs.loginForm.setErrors(error.response.data.error)
        // })
      }
      else {

      }
    },
  },
  mounted: async function () {
    localStorage.removeItem('crmData');
    localStorage.removeItem('dashboardData');
    localStorage.removeItem('report_cache');
    localStorage.removeItem('records_cache');
    this.getRegion();
    if (window.location.search != '') {
      let q_string = Object.fromEntries(new URLSearchParams(window.location.search).entries());
      console.log(q_string);
      if (q_string['username']) {
        this.username = atob(q_string['username']);
        this.password = atob(q_string['password']);
        // globalThis.qm_token = q_string['t'];
        // let _usr = JSON.parse(atob(q_string['t'].split('.')[1]));
        // console.log(_usr);
        // this.username = `${_usr.username}@${_usr.domain}`;
        // globalThis.username = `${_usr.username}@${_usr.domain}`;
        // this.$http_in.defaults.headers.common['authorization'] = response.token;
        // this.$http_in.defaults.headers.common['username'] = this.username;

        // await this.GetUserProjectAndQueues();
        this.login();
      }
    } else {
      const _userData = JSON.parse(localStorage.getItem('userData'));
      if (![undefined, null].includes(_userData)) {
        this.username = _userData.username;
        this.password = _userData.password;
      }
    }


  }

}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.vs__selected-options {
  max-height: 3.45vh; /* Adjust this value to suit your needs */
  overflow-y: auto;
}
</style>
