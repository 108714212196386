var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"login-bg",attrs:{"sm":"12","md":"12","lg":"12"}}),(!_vm.is_login_success)?_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4","offset-lg":"4"}},[_c('b-col',{attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-text',{staticClass:"mb-1 m font-weight-bold",attrs:{"title-tag":"h2"}},[_c('center',[_c('b-img',{staticClass:"mb-3",style:({
                filter: _vm.skin == 'dark' ? 'brightness(0) invert(1)' : '',
              }),attrs:{"width":"240px","height":"90px","src":"v_main_logo.png","alt":"logo"}})],1)],1),_c('b-card-title',{staticClass:"mb-1 font-weight-bold center-text",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.lang("t_welcome"))+" ")]),_c('b-card-text',{staticClass:"mb-2 center-text"},[_vm._v(" "+_vm._s(_vm.lang("t_pleaseSingIn"))+" ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mb-2"},[_c('label',{attrs:{"for":"login-username"}},[_vm._v(_vm._s(_vm.lang("t_username")))]),_c('validation-provider',{attrs:{"name":"username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-username","state":errors.length > 0 ? false : null,"name":"login-username","placeholder":_vm.lang('t_userName')},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v(_vm._s(_vm.lang("t_password")))]),_c('b-link',{on:{"click":_vm.forgot_password}},[_c('small',[_vm._v(_vm._s(_vm.lang("t_forgotPassword")))])])],1),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":_vm.lang('t_password')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mb-3"},[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.lang("t_rememberMe"))+" ")])],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.lang("t_signIn"))+" ")])],1)]}}],null,false,2378652751)})],1)],1):_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4","offset-lg":"4"}},[_c('b-col',{attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-text',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_c('center',[_c('b-img',{staticClass:"mb-3",style:({
                filter: _vm.skin == 'dark' ? 'brightness(0) invert(1)' : '',
              }),attrs:{"width":"240px","height":"90px","src":"v_main_logo.png","alt":"logo"}})],1)],1),_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(((_vm.lang("t_hello")) + " " + (_vm.username.split(".")[0]) + ","))+" ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.lang("t_PleaseSelectProjetAndQueues"))+" ")]),_c('b-form-group',{staticClass:"mb-2"},[_c('v-select',{attrs:{"options":_vm.projects,"placeholder":_vm.lang('t_projects'),"label":"display_name","reduce":function (val) { return val.internal_name; }},on:{"input":function($event){_vm.selected_queues = []}},model:{value:(_vm.selected_project),callback:function ($$v) {_vm.selected_project=$$v},expression:"selected_project"}})],1),_c('b-form-group',{staticClass:"mb-3"},[_c('v-select',{attrs:{"options":_vm.queues.filter(function (e) { return e.project == _vm.selected_project; }),"placeholder":_vm.lang('t_queues'),"label":"display_name","reduce":function (val) { return val.internal_name; },"multiple":"","selectable":function () { return _vm.static_queues.length == 0; }},on:{"option:deselected":_vm.deselect_queue},scopedSlots:_vm._u([{key:"search",fn:function(ref){
              var events = ref.events;
              var attributes = ref.attributes;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"maxlength":"2"}},'input',attributes,false),events))]}},{key:"list-header",fn:function(){return [_c('div',{staticClass:"w-100 mb-50"},[_c('b-button',{staticClass:"w-50",attrs:{"disabled":_vm.static_queues.length > 0,"size":"sm","variant":"outline-primary"},on:{"click":function () {
                      _vm.selected_queues = _vm.queues
                        .filter(function (e) { return e.project == _vm.selected_project; })
                        .map(function (e) { return e.internal_name; });
                    }}},[_c('feather-icon',{attrs:{"size":"14","icon":"CheckCircleIcon"}}),_vm._v(" "+_vm._s(_vm.lang("t_selectAll"))+" ")],1),_c('b-button',{staticClass:"w-50",attrs:{"disabled":_vm.static_queues.length > 0,"size":"sm","variant":"outline-danger"},on:{"click":function () {
                      _vm.selected_queues = [];
                    }}},[_c('feather-icon',{attrs:{"size":"14","icon":"XOctagonIcon"}}),_vm._v(" "+_vm._s(_vm.lang("t_cleanSelections"))+" ")],1)],1)]},proxy:true}]),model:{value:(_vm.selected_queues),callback:function ($$v) {_vm.selected_queues=$$v},expression:"selected_queues"}})],1),(_vm.selected_queues.length > 0)?_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":_vm.start}},[_vm._v(" "+_vm._s(_vm.lang("t_start"))+" ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }